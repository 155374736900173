import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { IoMdOpen } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import { GatsbyImage } from "gatsby-plugin-image";

import { useModal } from "../components/useModal";

import { Container, Wrapper, Row, Box } from "../components/util";
import { CallToAction, Modal } from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahGraphic from "../images/graphics/DroppahGraphic_Home.svg";

const HeroContainer = styled(Container)`
  background: url(${DroppahGraphic});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: ${(props) => props.theme.colours.white};

  .background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colours.white};
    top: 0;
    opacity: 0.6;
  }
`;

const VideoRow = styled(Row)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% + 30px);
  }
`;

const FilterBox = styled(Box)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: sticky;
    top: 100px;
  }
`;

const FilterItem = styled.a`
  display: block;
  padding: 10px 18px;
  background-color: ${(props) => props.theme.colours.black};
  /* border: solid 1px ${(props) => props.theme.colours.white}; */
  color: ${(props) => props.theme.colours.white};
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &.-active {
      &:hover {
        background-color: ${(props) => props.theme.colours.deeppurple};
        color: ${(props) => props.theme.colours.white};
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colours.deeppurple};
    }
  }

  &.-active {
    background-color: ${(props) => props.theme.colours.deeppurple};
    /* border: solid 1px ${(props) => props.theme.colours.deeppurple}; */
    color: ${(props) => props.theme.colours.white};
  }
`;

const VideoCardContainer = styled.div`
  position: relative;
  box-shadow: ${(props) => props.theme.styles.shadow};
  /* border-radius: 20px; */
  width: calc((100% / 2) - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    &.-full {
      width: 100%;
      padding-bottom: 0;

      .play-circle {
        height: 100px;
        width: 100px;

        svg {
          font-size: 40px;
        }
      }

      &:hover {
        .play-circle {
          height: 110px;
          width: 110px;
        }
      }
    }
  }

  &:hover {
    .play-circle {
      height: 80px;
      width: 80px;
    }
  }

  .play-circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    bottom: 30px;
    right: 30px;
    height: 70px;
    width: 70px;
    border-radius: 100px;
    background: ${(props) => props.theme.colours.white};
    box-shadow: ${(props) => props.theme.styles.shadow};
    transition: all ${(props) => props.theme.transitions.med};

    svg {
      font-size: 30px;
      color: ${(props) => props.theme.colours.black};
    }
  }
`;

const Tutorials = (props) => {
  var [embedLink, setEmbedLink] = useState("");
  var [embedTitle, setEmbedTitle] = useState("");
  const [itemModalOpen, setItemModalOpen] = useModal();
  const [country, setCountry] = useModal("New Zealand");

  useEffect(() => {
    if (props.location.search.startsWith("?video")) {
      var video = props.location.search.substr(7);
      setItemModalOpen(true);
      setEmbedLink(
        "https://player.vimeo.com/video/" +
          (video.indexOf("&") !== -1
            ? video.substring(0, video.indexOf("&"))
            : video)
      );
      setEmbedTitle("Droppah Guide");
    }
    setCountry(window.localStorage.getItem("user_country"));
  }, [props.location.search, embedLink, embedTitle, country]);

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulVideo(sort: { fields: title, order: ASC }) {
        edges {
          node {
            thumbnail {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            url
            topic
            title
          }
        }
      }
    }
  `);

  const filters = [
    "All",
    "Customer Story",
    "Employees",
    "Getting Started",
    "Payroll",
    "Photo Time Clock",
    "Rostering",
    "Timesheets",
  ];

  const [filterVal, setFilterVal] = useState("All");

  const handleChange = function(e) {
    setFilterVal(e.target.name);
  };

  function handleModal(url, title) {
    setItemModalOpen(true);
    setEmbedLink(url);
    setEmbedTitle(title);
  }

  const showVideo = (video) => {
    if (video.topic === filterVal || filterVal === "All") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Layout>
      <Seo
        title="Droppah Video Guides | Rostering Guides"
        description="Watch our bite-sized how to videos to learn how to master the different features and functions in Droppah's rostering software."
        pathname={props.location.pathname}
      />
      <Container css={{ height: "100%", overflow: "inherit" }}>
        <Wrapper stackGap={80}>
          <Box className="-textCenter" stackGap={10}>
            <h1 className="h4">
              <IoPlay css={{ top: "2px", position: "relative" }} /> Droppah
              Video Guides
            </h1>
            <h4 className="h1 -fontLarge">Watch & Learn</h4>
          </Box>
          <Row stackGap={40}>
            <FilterBox size={20} stackGap={30}>
              <Box stackGap={10}>
                {filters.map((item, i) => {
                  return (
                    <FilterItem
                      className={item === filterVal && "-active"}
                      onClick={handleChange}
                      name={item}
                      key={i}
                    >
                      {item}
                    </FilterItem>
                  );
                })}
              </Box>
              <p css={{ fontSize: "1rem" }}>
                Visit the{" "}
                <a href="https://support.droppah.com/hc/en-us" target="_blank">
                  Droppah Support Centre
                </a>{" "}
                to learn more.
              </p>
            </FilterBox>
            <Box size={80}>
              <VideoRow isWrap justify="flex-start">
                {contentfulData.allContentfulVideo.edges
                  .filter(function(item) {
                    return showVideo(item.node);
                  })
                  .map((edge, i) => {
                    return (
                      <VideoCardContainer
                        key={i}
                        className={
                          edge.node.title === "A Getting Started with Droppah"
                            ? "-full"
                            : ""
                        }
                        css={{
                          display:
                            edge.node.title ===
                              "A Getting Started with Droppah & PayHero" &&
                            country !== "New Zealand"
                              ? "none"
                              : "",
                        }}
                        onClick={() => {
                          handleModal(edge.node.url, edge.node.title);
                        }}
                      >
                        {edge.node.thumbnail && (
                          <GatsbyImage
                            image={edge.node.thumbnail.gatsbyImageData}
                            alt={edge.node.title + ` | Droppah Video Guide`}
                            title={edge.node.title + ` | Droppah Video Guide`}
                          />
                        )}
                        <div className="play-circle">
                          <IoPlay />
                        </div>
                      </VideoCardContainer>
                    );
                  })}
              </VideoRow>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
      <Modal
        isActive={itemModalOpen}
        embedURL={embedLink + `?autoplay=1`}
        embedTitle={embedTitle + ` | Droppah Video Guide`}
        handleClose={() => setItemModalOpen(false)}
        isVideo
      />
    </Layout>
  );
};

export default Tutorials;
